import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { filter, map, Observable, of, take } from 'rxjs';

import { BaseAsiAuthService } from '@asi/auth/data-access-auth';
import type { SessionBase } from '@asi/auth/types-auth';
import { ConfigService } from '@cosmos/config';

import { CUSTOMER_PORTAL_COLLECTION_AUTH_STATE_TOKEN } from '../states';
import type { CollectionAccessTokenRequest } from '../types';

@Injectable({
  providedIn: 'root',
})
export class SednaAuthService extends BaseAsiAuthService<
  CollectionAccessTokenRequest,
  SessionBase
> {
  private _http: HttpClient;

  private readonly _sednaApiUrl: string;

  constructor(
    private readonly _store: Store,
    handler: HttpBackend,
    configService: ConfigService
  ) {
    super();
    this._http = new HttpClient(handler);
    this._sednaApiUrl = configService.get('sednaApiUrl');
  }

  getToken(): Observable<string> {
    return this._store.select(CUSTOMER_PORTAL_COLLECTION_AUTH_STATE_TOKEN).pipe(
      map((state) => state?.session),
      filter(
        (session): session is SessionBase =>
          session != null && session.access_token != null
      ),
      map((session) => session.access_token!),
      take(1)
    );
  }

  refreshToken(): Observable<string | null> {
    return of(null);
  }

  override login(body: CollectionAccessTokenRequest): Observable<SessionBase> {
    return this._http
      .post<string>(`${this._sednaApiUrl}/account/login`, body)
      .pipe(map(this._mapLoginResponse));
  }

  private _mapLoginResponse(token: string): SessionBase {
    return {
      access_token: token,
    };
  }
}
