import { Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Action, State, StateToken, type StateContext } from '@ngxs/store';
import { catchError, defer, EMPTY, tap } from 'rxjs';

import type { SessionBase } from '@asi/auth/types-auth';

import { CustomerPortalCollectionAuthActions } from '../actions';
import { SednaAuthService } from '../services';

export interface CustomerPortalCollectionAuthStateModel {
  session: SessionBase | null;
  // The `accessCode` is available only when the end-buyer is viewing the portal.
  accessCode: string | null;
}

export const CUSTOMER_PORTAL_COLLECTION_AUTH_STATE_TOKEN =
  new StateToken<CustomerPortalCollectionAuthStateModel>(
    'customerPortalCollectionAuth'
  );

@State<CustomerPortalCollectionAuthStateModel>({
  name: CUSTOMER_PORTAL_COLLECTION_AUTH_STATE_TOKEN,
  defaults: {
    session: null,
    accessCode: null,
  },
})
@Injectable()
export class CustomerPortalCollectionAuthState {
  constructor(private readonly _authService: SednaAuthService) {}

  @Action(CustomerPortalCollectionAuthActions.LoginWithAccessCode, {
    cancelUncompleted: true,
  })
  private _loginWithAccessCode(
    ctx: StateContext<CustomerPortalCollectionAuthStateModel>,
    { payload }: CustomerPortalCollectionAuthActions.LoginWithAccessCode
  ) {
    ctx.patchState({ accessCode: payload.accessCode });

    return defer(() => this._authService.login(payload)).pipe(
      tap((session: SessionBase) =>
        ctx.dispatch(
          new CustomerPortalCollectionAuthActions.LoginSuccess(session)
        )
      ),
      catchError(() => {
        ctx.dispatch(new Navigate(['/not-found']));
        return EMPTY;
      })
    );
  }

  @Action([CustomerPortalCollectionAuthActions.LoginSuccess])
  private _updateSession(
    ctx: StateContext<CustomerPortalCollectionAuthStateModel>,
    action: CustomerPortalCollectionAuthActions.LoginSuccess
  ) {
    const session = {
      ...action.session,
      expires_at: null as number | null,
    };

    ctx.patchState({ session });
  }
}
