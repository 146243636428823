import type { SessionBase } from '@asi/auth/types-auth';

import type { CollectionAccessTokenRequest } from '../types/collection-access-token-request';

const ACTION_SCOPE = '[Customer-Portal Collection Auth]';

export namespace CustomerPortalCollectionAuthActions {
  export class LoginWithAccessCode {
    static type = `${ACTION_SCOPE} Login With Access Code`;

    constructor(public payload: CollectionAccessTokenRequest) {}
  }

  export class LoginSuccess {
    static type = `${ACTION_SCOPE} LoginSuccess`;

    constructor(public session: SessionBase) {}
  }
}
